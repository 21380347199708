import { Controller } from '@hotwired/stimulus'

// Use this for explicit form submit (e.g. onchange)
export default class FormSubmit extends Controller {
  submit(event) {
    const form = event.target.closest('form')

    form.requestSubmit()
  }
}
