import { Controller } from '@hotwired/stimulus'

export default class RestoreScrollPosition extends Controller {
  connect() {
    this.element.addEventListener('turbo:before-fetch-request', () => {
      const scrollPosition = window.scrollY

      document.addEventListener('turbo:load', () => window.scrollTo(0, scrollPosition), { once: true })
    })
  }
}
